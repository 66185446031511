import Data from "../static/data/userDashboard.json";
import {AddressItemResponseDto} from "~/contract/dto/UserAddressRelated.dto";
import ApiService from "~/service/ApiService";
export const useUserDashboardStore = defineStore({
  id: "UserDashboard",

  state: () => {
    return {
      data: Data.data,
      user:{},
      addresses: [] as Array<AddressItemResponseDto>
    };
  },
  actions:{
    setUser(payload){
      this.user = payload
      useCookie('user').value =JSON.stringify(payload)
      
    },
    logoutUser(){
      this.user = {}
      useCookie('user').value =undefined
    },
  }
});
